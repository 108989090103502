<template>
  <el-dialog v-model="visible" :title="upload.title" width="400px" append-to-body @close="cancelForm">
    <el-upload ref="uploadRef" :limit="1" accept=".xlsx, .xls" :headers="upload.headers" :action="upload.url"
      :data="uploadData" :before-upload="handleBeforeUpload" :on-progress="onProgress" :on-success="onSuccess"
      :auto-upload="false" :file-list="fileList" drag v-loading="isLoading">
      <el-icon class="el-icon--upload"><upload-filled /></el-icon>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <template #tip>
        <div style="display: flex; align-items: center; gap: 10px;  justify-content: center;">
          <el-checkbox v-model="upload.updateSupport" /> 是否更新已经存在的用户数据
        </div>
        <div style="display: flex; align-items: center; gap: 10px;  justify-content: center;">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <el-link v-if="dAction" type="primary" target="_blank" @click="importTemplate">下载模板</el-link>
          <el-link v-else type="primary" target="_blank" :href="href">下载模板</el-link>
        </div>
        <slot name="tip"></slot>
      </template>
    </el-upload>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancelForm">取 消</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup name="ImportData">
import { reactive, h, ref } from 'vue'
import { getToken } from '@/utils/auth'
import { ElMessage } from 'element-plus'
const emits = defineEmits(['submitForm', 'cancel'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  uAction: {
    type: String,
    default: '/wms/insurePolicy/importData'
  },
  dAction: {  // /wms/appointment/importTemplate
    type: String,
    default: ''
  },
  template: {
    type: String,
    default: '登记模板'
  },
  sourceWay: {
    type: String,
    default: '1'
  },
  varietyCode: {
    type: String,
    default: ''
  },
  isWmsType: {
    type: Boolean,
    default: false
  }
})
const uploadData = ref({
  sourceWay: props.sourceWay,
  varietyCode: props.varietyCode
})
if (props.isWmsType == true) {
  uploadData.value = {
    sourceWay: props.sourceWay,
    varietyCode: props.varietyCode,
    isWms: 2
  }
}
// { sourceWay, varietyCode, isWms:2}
const isLoading = ref(false)
const fileList = ref([])
const uploadRef = ref()
const { proxy } = getCurrentInstance()
const href = ref(`/template/${props.template}.xlsx`)
// const href = ref(`/dist/template/${props.template}.xlsx`)
const upload = reactive({
  // 弹出层标题（用户导入）
  title: `导入数据`,
  // 设置上传的请求头部
  headers: { Authorization: 'Bearer ' + getToken() },
  // 上传的地址
  url: import.meta.env.VITE_APP_BASE_API + props.uAction,
})
function handleBeforeUpload(rawFile) {
  console.log('handleBeforeUpload', rawFile, rawFile.size / 1024 / 1024 > 10)
  if (rawFile.size / 1024 / 1024 > 10) {
    isLoading.value = false
    ElMessage({
      message: '上传的文件不能大于 10 M,请拆分上传!',
      type: 'warning',
    })
    return false
  }
  return true
}
// 提交
function submitForm() {
  console.log('uploadRef', uploadRef, fileList.value, uploadData.value)
  console.log('uploadRef', uploadRef.value)
  uploadData.value.varietyCode = props.varietyCode
  if (fileList.value.length > 0) {
    isLoading.value = true
    uploadRef.value.submit()
  } else {
    proxy.$modal.msgWarning("请先选择要导入的文件！")
  }
}
// 处理
const onProgress = (event, file, fileList) => {
  console.log('file', file)
}
// 成功
const onSuccess = (response, file, fileList) => {
  isLoading.value = false
  const { code, msg } = response
  ElMessage({
    type: code === 200 ? 'success' : 'warning',
    message: h('div', { innerHTML: msg })
  })
  setTimeout(() => {
    uploadRef.value.clearFiles()
    cancelForm()
  }, 500)
}

const importTemplate = () => {
  if (proxy.isWmsType) {
    proxy.download(
      `${props.dAction}`,
      {
        isWms: 2,
        varietyCode: props.varietyCode
      },
      `${props.template}_${new Date().getTime()}.xlsx`
    )
  } else {
    proxy.download(
      `${props.dAction}`,
      {
        varietyCode: props.varietyCode
      },
      `${props.template}_${new Date().getTime()}.xlsx`
    )
  }
  cancelForm()
}

const cancelForm = () => {
  emits('cancel', false)
}
watch(
  () => props.visible,
  (val) => {
    console.log(val, 999)
    if (!val) {
      fileList.value = []
    }
  },
  { immediate: true }
)
</script>