<template>
  <el-image fit="fill" :src="realSrc" :style="`width:${width}px;height:${height}px;`" :preview-src-list="realSrcList"
    append-to-body>
    <template #error>
      <div class="image-slot">
        <span style="font-size: 18px;font-weight: 700;">暂无图片</span>
      </div>
    </template>
  </el-image>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import { getFileUrl } from "@/api/wms/file";

const props = defineProps({
  src: {
    type: String,
    default: ""
  },
  width: {
    type: [Number, String],
    default: ""
  },
  height: {
    type: [Number, String],
    default: ""
  },
  baseUrl: {
    type: Boolean,
    default: false
  }
});

const realSrc = ref('');
const realSrcList = ref([]);

const getImagesUrl = async (url) => {
  const res = await getFileUrl(url);
  return res.data;
};

watch(() => props.src, async (newSrc) => {
  let real_src = newSrc.split(",")[0];
  if (props.baseUrl) {
    if (real_src) {
      real_src = await getImagesUrl(real_src);
    } else {
      real_src = ''
    }
  }
  realSrc.value = real_src;
}, { immediate: true });

watch(() => props.src, async (newSrc) => {
  let real_src_list = newSrc.split(",").filter(item => item && item.trim());
  let srcList = [];
  for (const item of real_src_list) {
    if (props.baseUrl) {
      const url = await getImagesUrl(item);
      srcList.push(url);
    } else {
      srcList.push(item);
    }
  }
  realSrcList.value = srcList;
}, { immediate: true });
</script>

<style lang="scss" scoped>
.el-image {
  border-radius: 5px;
  background-color: #ebeef5;
  box-shadow: 0 0 5px 1px #ccc;

  :deep(.el-image__inner) {
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
    }
  }

  :deep(.image-slot) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #909399;
    font-size: 30px;
  }
}
</style>