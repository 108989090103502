import request from '@/utils/request'

// 新查询出单列表
export function listOrderSumNew(query) {
  return request({
    url: '/wms/outOrder/listSumNew',
    method: 'get',
    params: query
  })
}
// 旧查询出单列表
export function listOrderSum(query) {
  return request({
    url: '/wms/outOrder/listSum',
    method: 'get',
    params: query
  })
}

// 查询出单列表
export function listOrder(query) {
  return request({
    url: '/wms/outOrder/list',
    method: 'get',
    params: query
  })
}

// 查询出单详细
export function getOrder(id) {
  return request({
    url: '/wms/outOrder/' + id,
    method: 'get'
  })
}

// 查询出单详细
export function getOutOrder(id) {
  return request({
    url: '/wms/outOrder/' + id,
    method: 'get'
  })
}

// 打印详细
export function getPrint(id) {
  return request({
    url: "/wms/outOrder/print/" + id,
    method: "get",
  });
}

// 新增出单
export function addOutOrder(data) {
  return request({
    url: '/wms/outOrder',
    method: 'post',
    data: data
  })
}
// 新增出单
export function saveOutOrder(data) {
  return request({
    url: '/wms/outOrder/save',
    method: 'post',
    data: data
  })
}
// 修改出单
export function updateOrder(data) {
  return request({
    url: '/wms/outOrder',
    method: 'put',
    data: data
  })
}

// 审核出库单
export function auditOrder(data) {
  return request({
    url: '/wms/outOrder/audit',
    method: 'post',
    data: data
  })
}

// 出库单红冲
export function reverseOrder(data) {
  return request({
    url: '/wms/outOrder/reverse/',
    method: 'post',
    data: data
  })
}

// 删除出单
export function delOrder(id) {
  return request({
    url: '/wms/outOrder/' + id,
    method: 'delete'
  })
}

//上传凭证
export function uploadOrderFile(data) {
  return request({
    url: '/wms/outOrder/uploadCertificate',
    method: 'post',
    data: data
  })
}

//上传凭证
export function batchUploadOrderFiles(data) {
  return request({
    url: '/wms/outOrder/uploadCertificates',
    method: 'post',
    data: data
  })
}

// 出库单详情
export function getOutOrderDetailByCloudCode(cloudCode) {
  return request({
    url: '/wms/outOrder/getInfoByCloudCode/' + cloudCode,
    method: 'get',
  })
}
// 根据ID查询出库单详情(新)
export function getInfoByOutOrderId(id) {
  return request({
    url: '/wms/outOrder/getInfoByOutOrderId/' + id,
    method: 'get',
  })
}
// 查询出库车辆抓拍接口
export function getOutOrderCarSnapshoot(cloudCode) {
  return request({
    url: '/wms/outOrder/getCarSnapshoot/' + cloudCode,
    method: 'get'
  })
}
// 查询出库垛位物联网图片接口
export function getSnapshotInOut(cloudCode) {
  return request({
    url: '/wms/outOrder/getSnapshotInOut/' + cloudCode,
    method: 'get'
  })
}
// 查询出库垛位物联网图片接口(新)
export function getCarPictureByCloudCode(cloudCode) {
  return request({
    url: '/wms/outOrder/getCarPictureByCloudCode/' + cloudCode,
    method: 'get'
  })
}
// 平台入库审核
export function outExamine(data) {
  return request({
    url: '/wms/outOrder/outExamine',
    method: 'POST',
    data
  })
}