import request from '@/utils/request'

// 查询车辆抓拍列表
export function listPicture(query) {
  return request({
    url: '/wms/carPicture/list',
    method: 'get',
    params: query
  })
}
// 按时间查询车辆抓拍
export function getCarPictureList(query) {
  return request({
    url: '/wms/carPicture/getCarPictureList',
    method: 'get',
    params: query
  })
}
// 查询车辆抓拍详细
export function getPicture(id) {
  return request({
    url: '/wms/carPicture/' + id,
    method: 'get'
  })
}

// 新增车辆抓拍
export function addPicture(data) {
  return request({
    url: '/wms/carPicture',
    method: 'post',
    data: data
  })
}

// 修改车辆抓拍
export function updatePicture(data) {
  return request({
    url: '/wms/carPicture',
    method: 'put',
    data: data
  })
}

// 删除车辆抓拍
export function delPicture(id) {
  return request({
    url: '/wms/carPicture/' + id,
    method: 'delete'
  })
}
