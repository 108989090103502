<template>
  <el-dialog title="上传凭证" v-model="isShow" :close-on-click-modal="false" width="700px" append-to-body destroy-on-close
    :before-close="close">
    <div>
      <p style="color: #000">
        {{ '请上传图片大小不超过10M，格式为jpg/jpeg/png/pdf格式的磅单凭证。' }}
      </p>
      <el-form ref="addCarFile" :model="carForm">
        <el-form-item label="磅单凭证" prop="wbCertificateFile">
          <UploadFile ref="carFileRef" v-model="carForm.wbCertificateFile" :multiple="true" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="sure">确定</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive } from "vue";
import { saveCarFile } from "@/api/wms/order/inOrder";
import { updateTruckFilePc } from '@/api/wms/appointment/outAppointment'
import { ElMessage } from "element-plus";
const props = defineProps({
  isShow: {
    type: Boolean,
    default: false,
  },
  data: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(["update:isShow", "change"]);

const isShow = computed({
  get: () => props.isShow,
  set: (value) => emit("update:isShow", value),
})
const addCarFile = ref();
const carFileRef = ref();
const carForm = reactive({
  wbCertificateFile: "",
});

watch(
  () => props.isShow,
  (val) => {
    if (val) {
      carForm.wbCertificateFile = props.data
    }
  }
);

const sure = async () => {
  carForm.wbCertificateFile = carFileRef.value.fileList
    .map((item) => {
      return item.link;
    })
    .join(",") || '';
  if (!carForm.wbCertificateFile) {
    ElMessage({
      type: "warning",
      message: "请上传磅单凭证",
    });
    return;
  }
  emit("change", carForm.wbCertificateFile)
  isShow.value = false
};
function close() {
  isShow.value = false
}
</script>
