<template>
  <el-popover popper-class="show-files-new-box" ref="popoverRef" placement="left" :width="fileWidth" trigger="hover"
    @before-enter="showImagesUrl(props.files)">
    <template #reference>
      <div class="file-class">
        <el-image :src="circle2" fit="cover" v-if="fileList2.length > 0" style="margin-right:5px" />
        <el-image :src="circle2" fit="cover" v-if="fileList2.length > 1" style="margin-right:5px" />
        <el-image :src="circle2" fit="cover" v-if="fileList2.length > 2" style="margin-right:5px" />
        <span v-if="fileList2.length > 3">...</span>
        <el-image v-if="fileList2.length === 0" :src="circle1" fit="cover" />
      </div>
    </template>
    <div class="file-box">
      <div class="file-box-item" v-for="file in fileList" :key="file.url">
        <el-link v-if="file.type === 'pdf' || file.type === 'PDF'" :href="file.url" target="_blank">
          <el-image :src="pdfImage" fit="cover" class="file" />
        </el-link>
        <el-link v-else-if="file.type === 'rar' || file.type === 'zip'" :href="file.url" target="_blank">
          <el-image :src="rarImage" fit="cover" class="file" />
        </el-link>
        <el-image v-else :src="file.url" :preview-teleported="true" :preview-src-list="images" fit="cover" class="file" />
      </div>
      <div v-if="fileList2.length === 0">
        <span>{{ notes ? notes : '暂无图片，待仓库上传' }}</span>
      </div>
    </div>
  </el-popover>
</template>
<script setup name="ShowFiles">
import { getFileUrl } from "@/api/wms/file"
import { computed, watch, ref, onMounted } from "vue"
import pdfImage from '@/assets/images/pdf.png'
import rarImage from '@/assets/images/rar.png'
import circle1 from '@/assets/images/circle1.png'
import circle2 from '@/assets/images/circle2.png'
const { proxy } = getCurrentInstance()
const emits = defineEmits(['getFile'])
const props = defineProps({
  files: {
    type: [String],
    default: ''
  },
  notes: {
    type: [String],
    default: ''
  }
})
const popoverRef = ref()
const fileWidth = ref(240)
const fileList = ref([])
const images = computed(() => {
  return fileList.value.filter(item => item.type !== 'pdf' && item.type !== 'PDF' && item.type !== 'rar' && item.type !== 'zip').map(item => {
    return item.url
  }) || []
})

async function showImagesUrl(files) {
  if (files) {
    files = files.replace(/,$/, '')
    const arr = files.split(',').filter(item => item !== '')
    const res = arr.map(async (item) => {
      const type = item.split('.')[1]
      const urls = await getImagesUrl(item)
      return { type, url: urls.join() }
    })
    fileList.value = await Promise.all(res)
  } else {
    fileList.value = []
  }

}


// 批量获取图片路径
async function getImagesUrl(pic) {
  let urls = []
  if (
    pic &&
    pic.split(",").length > 0
  ) {
    urls = pic.split(",")
    return await Promise.all(
      urls.map(async (it) => {
        const res = await getFileUrl(it)
        return res.data
      })
    )
  }
}
watch(
  () => fileList.value,
  (val) => {
    if (val.length >= 1 && val.length <= 10) {
      fileWidth.value = val.length * 77
    } else if (val.length > 10) {
      fileWidth.value = 770
    }
  },
  { deep: true, immediate: true }
)
const fileList2 = ref([])
watch(
  () => props.files,
  (val) => {
    console.log('props.files', props.files)
    if (val) {
      fileList2.value = val.split(",").filter((item) => item !== "")
    } else {
      fileList2.value = []
    }
  },
  { deep: true, immediate: true }
)
</script>
<style lang="less">
.show-files-new-box {
  min-width: 85px !important;
}
</style>
<style scoped lang="scss">
.file-box {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;

  &-item {
    .file {
      width: 60px;
      height: 60px;
      padding: 5px;
      border: 1px solid rgba(0, 0, 0, .08);
      border-radius: 4px;
      box-sizing: border-box;
    }
  }
}

.file-class {
  width: 100%;
  height: 100%;
  display: flex; // 确保 display 属性设置为 flex
  align-items: center; // 确保内容垂直居中
  box-sizing: border-box;
  flex-wrap: nowrap;
}
</style>