<template>
  <div class="file-container">
    <transition-group class="file-list" name="el-fade-in-linear" tag="ul">
      <li v-for="(file, index) in fileList" :key="file.url" class="file-list-item"
        style=" display: flex; justify-content: center">
        <el-image :src="file.url" fit="contain" style="width: 180px; height: 90px;color: black;" />
        <span style="position: absolute; bottom: -10px; display: inline;font-size: 11px;">
          抓拍时间:{{ file.time }}
        </span>
        <el-checkbox style="position: absolute; bottom: -35px;" v-model="file.isCheck">选中</el-checkbox>
        <div class="file-list-item-action">
          <el-icon color="#fff" title="点击预览" @click="handlerPreview(file.type, file.url, index)">
            <View />
          </el-icon>
        </div>
      </li>
    </transition-group>
  </div>
</template>
<script setup name="UploadFile">
import { ref, reactive, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import { getFileUrl } from '@/api/wms/file'
import { preview } from "vue3-preview-image"
import { upmsFilePath } from '@/api/wms/home/home'
const store = useStore()
const emit = defineEmits(['update:modelValue', 'uploadImage'])
const { proxy } = getCurrentInstance()
const props = defineProps({
  modelValue: Array,
})
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    console.log()
    emit('update:modelValue', value)
  }
})
const fileList = ref([])
const disabled = ref(false)

// pdf 预览
const dialogVisible = ref(false)
const jsPdf = ref()
const handlerPreview = (type, url, index) => {
  const arr = fileList.value.map(item => item.url)
  console.log(index, arr)
  preview(index, arr)
}


watch(
  () => props.modelValue,
  async (val) => {
    if (val) {
      const images = await getImagesUrl(val)
      console.log(images)
      fileList.value = images
    } else {
      fileList.value = []
    }
  },
  {
    immediate: true,
    deep: true
  }
)

// 批量获取图片路径
async function getImagesUrl(pic) {
  let urls = []
  if (
    pic &&
    pic.length > 0
  ) {
    urls = pic.filter(item => item.url && item.url.trim())//去除空值
    return await Promise.all(
      urls.map(async (it) => {
        const type = it.url.substring(it.url.lastIndexOf('.') + 1)
        let res = await getFileUrl(it.url)
        return {
          type,
          link: it,
          time: it.time,
          url: res.data,
          isCheck: it.isCheck
        }
      })
    )
  }
}
defineExpose({
  fileList,
})
</script>

<style lang="scss" scoped>
.file-container {
  display: flex;
  align-items: center;
  gap: 10px;

  .file-list {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    &-item {
      position: relative;
      width: 180px;
      height: 120px;
      padding: 5px;
      margin-bottom: 20px;
      border: rgba(0, 0, 0, .1) 1px solid;
      border-radius: 4px;

      .pdf {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      .image {
        width: 180px;
        height: 120px;
      }

      &-action {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding: 20px;
        box-sizing: border-box;
        opacity: 0;
        border-radius: 4px;

        .el-icon {
          font-size: 18px;
          cursor: pointer;
        }
      }

      &:hover .file-list-item-action {
        background-color: rgba(0, 0, 0, .25);
        opacity: 1;
        transform: all linear 0.5s;
      }
    }
  }
}
</style>