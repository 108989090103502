import request from '@/utils/request'

// 旧版入库汇总查询接口
export function inOrderSum(query) {
  return request({
    url: '/wms/inOrder/listSum',
    method: 'get',
    params: query
  })
}
// 新版入库汇总查询接口
export function inOrderSumNew(query) {
  return request({
    url: '/wms/inOrder/listSumNew',
    method: 'get',
    params: query
  })
}

// 查询入库单列表
export function listOrder(query) {
  return request({
    url: '/wms/inOrder/list',
    method: 'get',
    params: query
  })
}

// 查询入库单详细
export function getOrder(id) {
  return request({
    url: '/wms/inOrder/' + id,
    method: 'get'
  })
}

// 打印详细
export function getPrint(id) {
  return request({
    url: "/wms/inOrder/print/" + id,
    method: "get",
  });
}

// 查询入库单复合信息
export function getOrderData(id) {
  return request({
    url: '/wms/inOrder/' + id,
    method: 'get'
  })
}

// 新增入库单
export function submitStack(data) {
  return request({
    url: '/wms/inOrder/submitStack',
    method: 'post',
    data: data
  })
}

// 入库单上垛
export function addOrder(data) {
  return request({
    url: '/wms/inOrder',
    method: 'post',
    data: data
  })
}

// 修改入库单
export function updateOrder(data) {
  return request({
    url: '/wms/inOrder',
    method: 'put',
    data: data
  })
}

// 入库单上垛2
export function addOrderNotCheck(data) {
  return request({
    url: '/wms/inOrder/addNotCheck',
    method: 'post',
    data: data
  })
}

// 修改入库单2
export function updateOrderNotCheck(data) {
  return request({
    url: '/wms/inOrder/editNotCheck',
    method: 'put',
    data: data
  })
}


// 审核入库单
export function auditOrder(data) {
  return request({
    url: '/wms/inOrder/audit',
    method: 'post',
    data: data
  })
}

// 入库单红冲
export function reverseOrder(data) {
  return request({
    url: '/wms/inOrder/reverse/',
    method: 'post',
    data: data
  })
}

// 删除入库单
export function delOrder(id) {
  return request({
    url: '/wms/inOrder/' + id,
    method: 'delete'
  })
}

//上传凭证
export function uploadOrderFile(data) {
  return request({
    url: '/wms/inOrder/uploadCertificate',
    method: 'post',
    data: data
  })
}

//上传凭证
export function batchUploadOrderFiles(data) {
  return request({
    url: '/wms/inOrder/uploadCertificates',
    method: 'post',
    data: data
  })
}

//无磅入库-建议可以补录司机证件
export function saveCarFile(data) {
  return request({
    url: '/wms/appointment/updateVehicleVoucher',
    method: 'post',
    data: data
  })
}
// 系统参数IsWithWeighbridge(1不关联地磅，2关联地磅，3两者都有)
export function getIsWithWeighbridge() {
  return request({
    url: '/system/config/configKey/IsWithWeighbridge',
    method: 'get'
  })
}

// 入库单详情
export function getOrderDetailByCloudCode(cloudCode) {
  return request({
    url: '/wms/inOrder/getInfoByCloudCode/' + cloudCode,
    method: 'get',
  })
}

// 查询入库车辆抓拍接口
export function getInOrderCarSnapshoot(cloudCode) {
  return request({
    url: '/wms/inOrder/getCarSnapshoot/' + cloudCode,
    method: 'get'
  })
}
// 查询车辆入场抓拍和出场抓拍接口
export function getCarSnapInOut(cloudCode) {
  return request({
    url: '/wms/inOrder/getCarSnapInOut/' + cloudCode,
    method: 'get'
  })
}
// 查询入库单垛位已选择的抓拍
export function getSnapshotInOut(id) {
  return request({
    url: '/wms/inOrder/getSnapshotInOut/' + id,
    method: 'get'
  })
}
// 查询入库单垛位抓拍(新)
export function getCarPictureInById(id) {
  return request({
    url: '/wms/inOrder/getCarPictureInById/' + id,
    method: 'get'
  })
}
// 平台入库审核
export function inExamine(data) {
  return request({
    url: '/wms/inOrder/inExamine',
    method: 'POST',
    data
  })
}
// 查询入库单垛位抓拍(新1)
export function getNewCarPictureInById(id) {
  return request({
    url: '/wms/inOrder/getNewCarPictureInById/' + id,
    method: 'get'
  })
}